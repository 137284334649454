import React, { Component } from "react";
import "../auth/auth.css";
import Slider from "react-slick";
import { TypeAnimation } from "react-type-animation";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import { SiLinkedin } from "react-icons/si";
import { RxLinkedinLogo } from "react-icons/rx";
import { BsTwitterX } from "react-icons/bs";
import LanguageSelector from "../../component/LanguageSelector";
import { useTranslation } from "react-i18next";
import { withTranslation } from 'react-i18next';


const settings = {
  // dots: true,
  infinite: true,
  speed: 1000,

  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  autoplay: true,
  autoplaySpeed: 10000,
  waitForAnimate: true

};

class Register extends Component {
  state = {
    current: "Signin",
  };

  alterAnimation = () => {
    let { current } = this.state;

    const signup = document.getElementById("signup");
    const login = document.getElementById("login");

    if (current === "Signin") {
      signup.classList.add("Route__Component_enterActive");
      login.classList.add("Route__Component_exit");
      login.classList.add("Route__Component_exitActive");

      current = "Signup";
      this.setState({
        current,
      });
    } else {
      login.classList.add("Route__Component_enterActive");
      signup.classList.add("Route__Component_exit");
      signup.classList.add("Route__Component_exitActive");

      current = "Signin";
      this.setState({
        current,
      });
    }
  };
  render() {
    const { current } = this.state;
    const {t}=this.props
    return (
      <>
        <div className="auth-page">
          <div className="sider">
            <Slider {...settings}>
              <div className="slide-item-img one"></div>
              <div className="slide-item-img two"></div>
              <div className="slide-item-img three"></div>
            </Slider>
          </div>
          <div className="auth-form-outer">
            <div className="auth-form-inner">
            <LanguageSelector/>
              <p className="auth-head">{t('create_account')}</p>
              <div className="social-auth">
                <div className="social-item">
                  <FcGoogle/>
                </div>
                <div className="social-item" style={{
                  color:"#1877F2"
                }}>
                  <FaFacebook/>
                </div>
                
                <div className="social-item">
                  <BsTwitterX/>
                </div>
                <div className="social-item"
                style={{
                  color:"#0077B5"
                }}
                >
                  <RxLinkedinLogo/>
                </div>
              </div>
              <hr className="my-50"/>
              <p className="auth-or"><span>OR</span></p>
            </div>
          </div>
          <div className="absolute text-animated">
            <TypeAnimation
            deletionSpeed={250}
            speed={250}
              sequence={[
                "Connect schools", // Types 'One'
                500, // Waits 1s
                "Choose what fits you", // Deletes 'One' and types 'Two'
                () => {
                  console.log("Sequence completed");
                },
              ]}
              wrapper="span"
              className="textanim"
              repeat={Infinity}
              style={{ fontSize: "3em", display: "inline-block" }}
            />
          </div>
        </div>
      </>
    );
  }
}

export default  withTranslation()(Register);
