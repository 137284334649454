import { CButton, CCollapse, CContainer, CForm, CFormInput, CNavItem, CNavLink, CNavbar, CNavbarBrand, CNavbarNav, CNavbarToggler } from "@coreui/react";
import React, { useState } from "react";

const NavigationComponent = () => {

    const [visible,setVisible]=useState(false)
  return <>
  <CNavbar expand="md" className=""
  style={{
    backgroundColor:"#22225d !important",
    position:"fixed",
    width:"100%",
    zIndex:1000
  }}
  >
    <CContainer fluid>
      <CNavbarToggler
        aria-label="Toggle navigation"
        aria-expanded={visible}
        onClick={() => setVisible(!visible)}
      />
      <CNavbarBrand href="#">موقعنا</CNavbarBrand>
      <CCollapse className="navbar-collapse" visible={visible}>
        <CNavbarNav className="me-auto mb-2 mb-lg-0">
          <CNavItem>
            <CNavLink href="/" active>
              الرئيسية
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="/login">دخول</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="/register" disabled>
              إنشاء حساب
            </CNavLink>
          </CNavItem>
        </CNavbarNav>
        
      </CCollapse>
    </CContainer>
  </CNavbar>
</>
};

export default NavigationComponent;
