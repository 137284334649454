import { Button } from "antd";
import React, { Component } from "react";

class FeesStep extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <div className="fees-step installment-step">
        <div className="form-title">
       
       

          <span> قيمة التمويل المطلوب</span>
          <p className="fees-step-value">
            {" "}
            24,000 <span>ج م</span>
          </p>
          <p className="fees-installment">
            <span> 2000. ج م</span>
            شهريا لمدة
            <span>12 شهر</span>
          </p>
          <p className="indicate">
            الحسابات استرشادية يمكن أن تتغير عند الحجز الفعلي في المدرسة
          </p>
          <Button
            className="flow-next-btn"
            htmlType="submit"
            style={{
              height: "50px",
            }}
          >
            <a href="/">
            تقدم بالطلب الآن
            </a>
          </Button>
        </div>
      </div>
    );
  }
}

export default FeesStep;
