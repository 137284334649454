import cairo from "../../assets/cities/cairo.webp";
import alex from "../../assets/cities/alex.webp";
import giza from "../../assets/cities/giza.webp";
import madinah from "../../assets/cities/madinah2.jpeg";
import makka from "../../assets/cities/makkah2.jpeg";
import forsan from "../../assets/featured_schools/forasn.jpg";
import forsan2 from "../../assets/featured_schools/foran2.jpg";
import rwad from "../../assets/featured_schools/rwad.webp";
import anwar from "../../assets/featured_schools/anwar.jpeg";
import haya from "../../assets/featured_schools/qemmhayah.jpg";
import oroba from "../../assets/featured_schools/oroba.webp";
import oloom from "../../assets/featured_schools/oloom.webp";
import sharqa from "../../assets/featured_schools/sharqa.jpg";
import { RiGlobalFill } from "react-icons/ri";
import { FaPeopleArrows } from "react-icons/fa6";
import { FcBiohazard } from "react-icons/fc";
import { FcReadingEbook } from "react-icons/fc";
import { FcReading } from "react-icons/fc";
import { FcMultipleInputs } from "react-icons/fc";

export const schools_list = [
  {
    value: "دسمان النموذجية",
    label: "دسمان النموذجية",
  },
  {
    value: "الارمنـــية مختلط",
    label: "الارمنـــية مختلط",
  },
  {
    label: "الانجليزية الفحيحيل",
    value: "الانجليزية الفحيحيل",
  },
  {
    value: "الكويت الوطنية الانجليزية",
    label: "الكويت الوطنية الانجليزية",
  },
  {
    label: "الاكاديمية الدولية البريطانية في الكويت",
    value: "الاكاديمية الدولية البريطانية في الكويت",
  },
  {
    label: "الغـــانم",
    value: "الغـــانم",
  },
  {
    label: "البــيان ثنــائية اللغة",
    value: "البــيان ثنــائية اللغة",
  },
];

export const schools_grades = [
  {
    label: "روضة 1 (KG 1)",
    value: "kg1",
  },
  {
    label: "روضة 2 (KG 2)",
    value: "kg2",
  },
  {
    label: "تمهيدي (KG 3)",
    value: "kg3",
  },
  {
    label: "أول إبتدائي (Grade 1)",
    value: "grade-1",
  },
  {
    label: "ثاني إبتدائي (Grade 2)",
    value: "grade-2",
  },
  {
    label: "ثالث إبتدائي (Grade 3)",
    value: "grade-3",
  },
  {
    label: "رابع إبتدائي (Grade 4)",
    value: "grade-4",
  },
  {
    label: "خامس إبتدائي (Grade 5)",
    value: "grade-5",
  },
  {
    label: "سادس إبتدائي (Grade 6)",
    value: "grade-6",
  },
  {
    label: "أول متوسط (Grade 7)",
    value: "grade-7",
  },
  {
    label: "ثاني متوسط (Grade 8)",
    value: "grade-8",
  },

  {
    label: "ثالث متوسط (Grade 9)",
    value: "grade-9",
  },
  {
    label: "أول ثانوى  (Grade 10)",
    value: "grade-10",
  },
  {
    label: "ثاني ثانوي (Grade 11)",
    value: "grade-11",
  },
  {
    label: "ثالث ثانوي (Grade 12)",
    value: "grade-12",
  },
];

export const cities = [
  {
    label: "العاصمة",
    value: "asemah",
  },
  {
    label: "الأحمدي",
    value: "ahmadi",
  },
  {
    label: "الأندلس",
    value: "andalos",
  },
  {
    label: "حولي",
    value: "hawly",
  },
  {
    label: "الجهراء",
    value: "gahraa",
  },
  {
    label: "الفروانية",
    value: "frwaniah",
  },
  {
    label: "مبارك الكبير",
    value: "mubarak",
  },
  {
    label: "العارضية",
    value: "arediah",
  },
];

export const featured_cities = [
  {
    name: "الأحمدي",
    count: "500 مدرسة",
    image: cairo,
  },
  {
    name: "حولي",
    count: "350 مدرسة",
    image: alex,
  },
  {
    name: "الجهراء",
    count: "420 مدرسة",
    image: giza,
  },
  {
    name: "الفروانية",
    count: "100 مدرسة",
    image: makka,
  },
  {
    name: "العاصمة",
    count: "100 مدرسة",
    image: madinah,
  },
];
export const featured_schools = [
  {
    name: "دسمان النموذجية	",
    image: forsan,
    rate: 4.8,
    type: "مشتركة",
    type_icon: <FaPeopleArrows />,
    level: "لغات",
    icon: <RiGlobalFill />,
  },
  {
    name: "الارمنـــية مختلط",
    image: forsan2,
    rate: 4.8,
    type: "مشتركة",
    level: "تجريبي",
    icon: <FcBiohazard />,
    type_icon: <FcReading />,
  },
  {
    name: "الانجليزية الفحيحيل",
    image: rwad,
    rate: 4.5,
    type: "مشتركة",
    level: "تجريبي",
    icon: <FcBiohazard />,
    type_icon: <FaPeopleArrows />,
  },
  {
    name: "الكويت الوطنية الانجليزية",
    image: anwar,
    rate: 4.6,
    type: "بنات",
    level: "لغات",
    type_icon: <FcReading />,
    icon: <FcMultipleInputs />,
  },
  {
    name: "الاكاديمية الدولية البريطانية في الكويت",
    image: oroba,
    rate: 4.7,
    type: "مشتركة",
    level: "لغات",
    type_icon: <FaPeopleArrows />,
    icon: <FcMultipleInputs />,
  },
  {
    name: "الغـــانم",
    image: oloom,
    rate: 4.5,
    type: "مشتركة",
    level: "تجريبي",
    type_icon: <FaPeopleArrows />,
    icon: <FcBiohazard />,
  },
  {
    name: "البــيان ثنــائية اللغة",
    image: haya,
    rate: 4.6,
    type: "بنين",
    level: "لغات",
    type_icon: <FcReadingEbook />,
    icon: <FcMultipleInputs />,
  },

  {
    name: "مدارس الشارقة الأهلية ",
    image: sharqa,
    rate: 4.5,
    type: "مشتركة",
    level: "لغات",
    type_icon: <FaPeopleArrows />,
    icon: <FcMultipleInputs />,
  },
];
