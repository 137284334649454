import React, { Component } from "react";
import { Button, Col, Form, Input, Radio, Row, Select } from "antd";
import { schools_list } from "./schoolsList";
import { setBasicInfo } from "../../redux/actions/authActions";
import { connect } from "react-redux";

class BasicParentInfo extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  onSubmitBasicInfo = (values) => {
    console.log(values);
    this.props.setBasicInfo(values);
  };
  render() {
    return (
      <div className="basic-info-step installment-step">
        <div className="form-title">
          <span>خطوة 1 من 3</span>
          <p>البيانات الأساسية</p>
        </div>

        <Form layout="vertical" size="large" onFinish={this.onSubmitBasicInfo}>
          <Row gutter={20}>
            <Col md={12} sm={24}xs={24}>
              <Form.Item
                label="الإسم بالكامل"
                name="name"
                initialValue={this.props.parentInfo?.name}
              >
                <Input placeholder="أدخل الإسم بالكامل" />
              </Form.Item>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                label=" رقم الجوال"
                name="phone"
                initialValue={this.props.parentInfo?.phone}
              >
                <Input placeholder="xxxxxxxx 01" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                label="رقم الهوية الشخصية"
                name="national_id"
                initialValue={this.props.parentInfo?.national_id}
              >
                <Input placeholder="أدخل الرقم القومي" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={24} sm={24} xs={24}>
              <Form.Item
                label="إسم المدرسة"
                name="school_name"
                initialValue={this.props.parentInfo?.school_name}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="قم بالبحث باسم المدرسة"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLowerCase() ?? "").includes(
                      input?.toLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={schools_list}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button htmlType="submit" className="flow-next-btn">
              التالي
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

// export default BasicParentInfo;

const mapDispatchToProps = (dispatch) => ({
  setBasicInfo: (info) => dispatch(setBasicInfo(info)),
});
const mapStateToProps = (state) => {
  return {
    currentIndex: state.installmentReducer.stepIndex,
    parentInfo: state.installmentReducer.parentInfo,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BasicParentInfo);
