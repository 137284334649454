
import * as actions from '../constants'
import axios from 'axios'
// import {toast, Flip } from 'react-toastify';
export const setBasicInfo = (info)=>({
    type:actions.ADD_BASIC_INFO_STEP,
    payload:info
})
export const setChildrenInfo = (info) => ({
    type: actions.ADD_CHILDREN_INFO_STEP,
    payload:info
})
export const setTaqseetDocument = (docs) => ({
    type: actions.ADD_TAQSEET_DOCUMENT_STEO,
    payload:docs
})

export const goToStep = (step) => ({
    type: actions.GO_TO_STEP,
    payload:step
})