import React, { Component } from "react";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { Row, Col, Upload, Form, Button } from "antd";
import { setTaqseetDocument } from "../../redux/actions/authActions";
import { connect } from "react-redux";

class DocumentsStep extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      console.log(e);
      return e;
    }
    console.log(e?.fileList);
    return e?.fileList;
  };
  onSubmitUploadDocument = (values) => {
    this.props.setDocument(values);
  };
  render() {
    return (
      <div className="basic-info-step installment-step">
        <div className="form-title">
          <span>خطوه 3 من 3</span>
          <p> الأوراق المطلوبة</p>
        </div>
        <Form onFinish={this.onSubmitUploadDocument}>
          <Form.Item>
            <Form.Item
              name="national_id"
              valuePropName="fileList"
              getValueFromEvent={this.normFile}
              noStyle
              rules={[
                {
                  required: true,
                  message: "من فضلك أرفق صورة الرقم القومي",
                },
              ]}
            >
              <Upload.Dragger name="national_id">
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  من فضلك أرفق صورة من بطاقة الرقم القومي
                </p>
                <p className="ant-upload-hint">يدعم ملفات JPG, PDF , PNG</p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Form.Item
              name="salary_info"
              valuePropName="fileList"
              getValueFromEvent={this.normFile}
              noStyle
              rules={[
                {
                  required: true,
                  message: "من فضلك أرفق صورة من مفردات الدخل",
                },
              ]}
            >
              <Upload.Dragger name="national_id">
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  من فضلك أرفق صورة من مفردات الدخل الخاصة بكم
                </p>
                <p className="ant-upload-hint">يدعم ملفات JPG, PDF , PNG</p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" className="flow-next-btn">
              التالي
            </Button>
          </Form.Item>
        </Form>
        {/* <Row>
          <Col>
            <div className="upload-box">

            </div>
          </Col>
          <Col>
            <div className="upload-box"></div>
          </Col>
        </Row> */}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setDocument: (docs) => dispatch(setTaqseetDocument(docs)),
});
const mapStateToProps = (state) => {
  return {
    currentIndex: state.installmentReducer.stepIndex,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DocumentsStep);
