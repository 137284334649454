import React, { Component } from "react";
import { Form, Button, Row, Col, Input, Radio, Select } from "antd";
import { options, schools_grades } from "./schoolsList";
import { connect } from "react-redux";
import { goToStep, setChildrenInfo } from "../../redux/actions/authActions";
import forsan from "../../assets/featured_schools/forasn.jpg"
class ChildrenInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      children: [
        {
          grade: null,
          path: null,
          gender: "male",
        },
      ],
      childIncomplete: false,
    };
  }
  onSubmitChildrenInfo = (values) => {
    const { children } = this.state;
    const length = children.length;
    console.log(values);
    console.log(this.state.children);
    if (children[length - 1].path && children[length - 1].grade) {
      this.props.setChildrenInfo(children);
    } else {
      this.setState({
        childIncomplete: true,
      });
    }
  };
  addChildRow = () => {
    const { children } = this.state;
    const length = children.length;
    if (children[length - 1].path && children[length - 1].grade) {
      console.log("ADDED");
      this.setState({
        children: [...children, { path: null, path: null, gender: "male" }],
        childIncomplete: false,
      });
    } else {
      this.setState({
        childIncomplete: true,
      });
    }
  };
  changePath = (value, index) => {
    const { children } = this.state;
    children[index].path = value;
    this.setState({ children });
  };
  changeGender = (e, index) => {
    const { children } = this.state;

    children[index].gender = e.target.value;
    this.setState({ children });
  };
  changeGrade = (value, index) => {
    const { children } = this.state;

    children[index].grade = value;
    this.setState({ children });
  };
  render() {
    const { children } = this.state;
    return (
      <div className="children-info-step installment-step">
        <div className="form-title">
          <span>خطوة 2 من 3</span>
          <p>بيانات الأبناء</p>
        </div>
        <div className="selected-school relative">
        <img src={forsan} alt="" width={30}/>
        <span className="px-2"> {this.props.parentInfo?.school_name}</span>
        <span className="change-btn absolute pointer"
        onClick={()=>this.props.goToStep(0)}
        >
          تغيير المدرسة
          
        </span>
        </div>
        <Form
          layout="vertical"
          size="large"
          onFinish={this.onSubmitChildrenInfo}
        >
          {children?.map((child, index) => {
            return (
              <div className="child-row">
                <Row gutter={20} key={index}>
                  <Col md={12} sm={24}xs={24}>
                    <Form.Item label="السنة الدراسية" name={`grade-${index}`}>
                      <Select
                        showSearch
                        style={{
                          width: "100%",
                        }}
                        onChange={(e) => {
                          this.changeGrade(e, index);
                        }}
                        placeholder="إختر المرحلة"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label?.toLowerCase() ?? "").includes(
                            input?.toLowerCase()
                          )
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={schools_grades}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24}xs={24}>
                    <Form.Item label="المسار" name={`path-${index}`}>
                      <Select
                        showSearch
                        style={{
                          width: "100%",
                        }}
                        onChange={(e) => {
                          this.changePath(e, index);
                        }}
                        placeholder="إختر المسار"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label?.toLowerCase() ?? "").includes(
                            input?.toLowerCase()
                          )
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={[{ label: "عام", value: "general" }]}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col md={12}sm={24}xs={24}>
                    <Form.Item
                      name={`gender-${index}`}
                      initialValue={child.gender}
                      label="النوع"
                    >
                      <Radio.Group
                        onChange={(e) => this.changeGender(e, index)}
                      >
                        <Radio value="male">ذكر</Radio>
                        <Radio value="female">أنثى</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            );
          })}
          <p className="error red">
            {this.state.childIncomplete && "من فضلك ادخل الابن كاملة"}
          </p>
          <div
            className="add-child pointer my-3 underline"
            onClick={() => this.addChildRow()}
          >
            إضافه إبن آخر
          </div>
          <Form.Item>
            <Button className="flow-next-btn" htmlType="submit">
              التالي
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setChildrenInfo: (info) => dispatch(setChildrenInfo(info)),
  goToStep: (step) => dispatch(goToStep(step)),
});
const mapStateToProps = (state) => {
  return {
    currentIndex: state.installmentReducer.stepIndex,
    parentInfo:state.installmentReducer.parentInfo

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChildrenInfo);
