import './App.css';
import Register from './pages/auth/Register';
import { useTranslation } from "react-i18next";
import "./i18n";
import LanguageSelector from './component/LanguageSelector';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './pages/Homepage';
import NavigationComponent from './component/NavigationComponent';
import FlowWrapper from './pages/installment-flow/FlowWrapper';
function App() {
  return (
   <>
   {/* <Register/> */}

   <NavigationComponent/>
   <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Register />} />
        <Route path="/installment-financing" element={<FlowWrapper />} />
      </Routes>
    </Router>
   </>
  );
}

export default App;
