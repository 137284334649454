import React, { Component } from "react";
import BasicParentInfo from "./BasicParentInfo";
import "./installment.css";
import ChildrenInfo from "./ChildrenInfo";
import { connect } from "react-redux";
import DocumentsStep from "./DocumentsStep";
import FeesStep from "./FeesStep";
class FlowWrapper extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <div className="flow-wrapper ">
        {/* <BasicParentInfo /> */}

        <div className="flow-container py-5">
          <div className="flow-hero">
            <div className="flow-header">
              <p className="first">خدمة تقسيط الرسوم الدراسية على ١٢ شهر</p>
              <p className="second">قسط الآن</p>
              <p className="third">تطبق الشروط والأحكام</p>
            </div>
          </div>
          <div className="inner-flow">
            {/* <ChildrenInfo /> */}
            {this.props.currentIndex === 0 && <BasicParentInfo />}
            {this.props.currentIndex === 1 && <ChildrenInfo />}
            {this.props.currentIndex === 2 && <DocumentsStep />}
            {this.props.currentIndex === 3 && <FeesStep />}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentIndex: state.installmentReducer.stepIndex,
  };
};
export default connect(mapStateToProps)(FlowWrapper);

