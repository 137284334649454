import React, { Component } from "react";
import "./homepage.css";
import { Checkbox, Col, Form, Input, Row, Select ,Rate} from "antd";
import {
  cities,
  featured_cities,
  featured_schools,
} from "./installment-flow/schoolsList";
import taqseet from "../assets/Takseetold.webp";
import arrow from "../assets/arrow-left.webp";
class Homepage extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <div className="homepage" id="homepage">
        <div className="home-page-container deschool-container">
          <div className="hero">
            <div className="cover">
              <Row>
                <Col md={12}>
                  <div className="hero-text">
                    <h5 className="text-white">
                      إحجز المدرسة بسهولة ويسر من خلال موقعنا
                    </h5>
                    <h6 className="text-green first">
                      وادفع الرسوم الدراسية بالتقسيط على 12 شهر مع تسهيل للتمويل
                      في قائمة المدارس المعتمدة
                    </h6>
                    <h6 className="text-white second">
                      يوصَلك موقعنا بأكثر من{" "}
                      <span className="hero-counter">1500</span> مدرسة تم
                      مراجعتها والتأكد من بياناتها. تصفح أسرع ، إحجز أسرع
                    </h6>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="hero-background-img"></div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="home-filter">
            <div className="inner-filter">
              <div className="filter-controls">
                <Form layout="vertical" size="large">
                  <Row gutter={25}>
                    <Col md={6}>
                      <Form.Item name="school_name" label="اسم المدرسة">
                        <Input placeholder="ابحث باسم المدرسة" />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item label="اسم المحافظة" name="city">
                        <Select
                          showSearch
                          style={{
                            width: "100%",
                          }}
                          placeholder="ابحث بالحافظة"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label?.toLowerCase() ?? "").includes(
                              input?.toLowerCase()
                            )
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={cities}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        label="مدارس متاح بها التقسيط علي 12 شهر"
                        name="disabled"
                        valuePropName="checked"
                      >
                        <Checkbox>
                          <img
                            src={taqseet}
                            alt=""
                            style={{
                              maxWidth: "75px",
                            }}
                          />
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col></Col>
                  </Row>
                </Form>
              </div>
              <div className="school-by-city ">
                <Row justify={"space-between"} className="my-5">
                  <Col>
                    <h4>تصفح المدارس حسب المحافظه</h4>
                  </Col>
                  <Col>
                    <button className="all-btn">تصفح الكل</button>
                  </Col>
                </Row>
                <Row>
                  <div className="featuredCities">
                    {featured_cities?.map((city) => {
                      return (
                        <a href="#" className="city">
                          <div className="img">
                            <img src={city?.image} alt={city.name} />
                          </div>
                          <div className="text">
                            <p>{city.name}</p>
                            <span>{city.count}</span>
                            <p className="link">
                              تصفح المدارس
                              <img src={arrow} alt="" />
                            </p>
                          </div>
                        </a>
                      );
                    })}
                  </div>
                </Row>
              </div>
              <div className="school-by-city ">
                <Row justify={"space-between"} className="my-5">
                  <Col>
                    <h4>تصفح المدارس حسب المحافظه</h4>
                  </Col>
                  <Col>
                    <button className="all-btn">تصفح الكل</button>
                  </Col>
                </Row>
                 <div className="featuredSchools">
                <Row gutter={[20,20]}>
                 
                    {featured_schools?.map((school) => {
                      return (
                        <Col md={6} sm={8} xs={24}>
                          <a href="#" className="school">
                            <div className="top">
                              <div className="card-head-row">
                                <span className="head-grid">
                                {school.icon}
                                {school.type}
                                </span>
                                <span>
                                  <Rate  defaultValue={4} disabled/>
                                </span>
                              </div>
                              <div className="school-main">
                                <div className="school-img"
                                style={{
                                  backgroundImage:`url("${school.image}")`
                                }}
                                >
                                </div>
                                <div className="school-name">
                                  <p>
                                    {school.name}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="bottom">
                              <span className="fees">الرسوم تبدأمن 15000</span>
                              <div className="school-btns">
                                <a href="/installment-financing" className="installment">
                                  قسَط الرسوم
                                </a>
                                <a href="#" className="book">
                                  احجز الآن
                                </a>
                              </div>
                            </div>
                          </a>
                        </Col>
                      );
                    })}
               
                </Row>
                   </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Homepage;
